.zero-padding-top .form-check-label {
  padding-top: 0;
}
.padding-55 {
  padding: 0 5px 0 5px !important;
}
.padding-5 {
 padding-left: 5px !important;
}
.padding-left-025 {
  padding-left: 0.25rem;
}